<template>
  <div>
    <pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
  components: {
    pdf,
  },
  data() {
    return {
      url: '',
      numPages: null, // pdf 总页数
    }
  },
  mounted() {
    this.url = this.$route.query.pdfUrl
    this.getNumPages()
  },
  methods: {
    //计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url)
      loadingTask.promise.then((pdf) => {
        this.numPages = pdf.numPages
      })
    },
  },
}
</script>
